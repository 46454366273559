import { routes } from './routes';

const pages = {
    allBwics: 'AllBwics',
    portfolio: 'Portfolio',
    bwicOpenBidding: 'BwicOpenBidding',
};

const urls = {
    [pages.allBwics]: routes.allBWICs,
    [pages.portfolio]: routes.portfolio,
};

const items = {
    [pages.allBwics]: [
        {
            message: 'Filtering by rating',
            video: ''
        },
        {
            message: 'Search with identifiers',
            video: ''
        },
        {
            message: 'See the rules if they are available',
            video: ''
        },
        {
            message: 'View BWIC',
            video: ''
        },
    ],
    [pages.portfolio]: [
        {
            message: 'Upload a list of securities to create a new portfolio.',
            video: ''
        },
        {
            message: 'Search with identifiers.',
            video: ''
        },
        {
            message: 'Export portfolios.',
            video: ''
        },
        {
            message: 'Toggle on to receive an email notification once a security from the portfolio is on a BWIC.',
            video: ''
        },
        {
            message: 'See historical BWICs associated with the security.',
            video: ''
        },
    ]
};

export const onBoardingTooltipConst = {
    maxCountLimit: 3,
    localStorageKey: 'onBoardingTooltipState',
    pages,
    items,
    urls
};
