import { PipelineColumn } from '../../components/amrPipeline/types/PipelineColumn';

export const pipelineColumnLabels: Partial<Record<PipelineColumn, string>> = {
    [PipelineColumn.esg]: 'ESG',
    [PipelineColumn.amrDeal]: 'Deal with AMR',
    [PipelineColumn.amrClass]: 'AMR Class',
    [PipelineColumn.arranger]: 'Arranger',
    [PipelineColumn.toAllArrangers]: '',
    [PipelineColumn.toAllManagers]: '',
    [PipelineColumn.toRelatedDeals]: '',
    [PipelineColumn.balance]: 'Balance',
    [PipelineColumn.capMargin]: 'Cap Margin, %',
    [PipelineColumn.className]: 'Class',
    [PipelineColumn.closingDate]: 'Closing',
    [PipelineColumn.coupon]: 'Coupon',
    [PipelineColumn.collateralType]: 'Collat.',
    [PipelineColumn.cushion]: 'O/C Cushion, %',
    [PipelineColumn.currency]: 'Ccy',
    [PipelineColumn.dealName]: 'Deal Name',
    [PipelineColumn.dealSize]: 'Trans. Size',
    [PipelineColumn.dm]: 'DM, bps',
    [PipelineColumn.ds]: 'DS',
    [PipelineColumn.euCompliance]: 'EU Comp.',
    [PipelineColumn.factor]: 'Factor',
    [PipelineColumn.guidance]: 'Guidance',
    [PipelineColumn.initialGuidance]: 'Initial Guidance',
    [PipelineColumn.lastUpdated]: 'Last Updated',
    [PipelineColumn.manager]: 'Manager',
    [PipelineColumn.margin]: 'Margin, %',
    [PipelineColumn.mvoc]: 'MVOC, %',
    [PipelineColumn.nonAmr]: 'Non-AMR',
    [PipelineColumn.nonCallPeriodEnd]: 'NCE',
    [PipelineColumn.nonCallPeriodEndDays]: 'NCE, yrs',
    [PipelineColumn.nonCallPeriodEndYears]: 'NCE, yrs',
    [PipelineColumn.offeringType]: 'Offering Type',
    [PipelineColumn.parSubordination]: 'C/E, %',
    [PipelineColumn.price]: 'Price',
    [PipelineColumn.initialPrice]: 'Initial Price',
    [PipelineColumn.pricingDate]: 'Pricing',
    [PipelineColumn.rating]: 'Rtg (M/S/F/K/D)',
    [PipelineColumn.reinvestmentPeriodEnd]: 'RIE',
    [PipelineColumn.reinvestmentPeriodEndDays]: 'RIE, yrs',
    [PipelineColumn.reinvestmentPeriodEndYears]: 'RIE, yrs',
    [PipelineColumn.subscription]: 'Subscription',
    [PipelineColumn.target]: 'O/C Target, %',
    [PipelineColumn.transactionStatus]: 'Status',
    [PipelineColumn.transactionType]: 'Type',
    [PipelineColumn.transactionSize]: 'Transaction Size',
    [PipelineColumn.trigger]: 'O/C Trigger, %',
    [PipelineColumn.wal]: 'WAL',
    [PipelineColumn.warf]: 'WARF',
    [PipelineColumn.was]: 'WAS, %',
    [PipelineColumn.maturity]: 'Maturity',
    [PipelineColumn.vintage]: 'Vintage',
    [PipelineColumn.firstPayment]: 'First Payment',
    [PipelineColumn.intex]: 'Intex',
    [PipelineColumn.numberOfDocuments]: 'Docs',
    [PipelineColumn.syndicate]: 'Syndicate',
    [PipelineColumn.enhancedCLO]: 'Enhanced',
    [PipelineColumn.staticDeal]: 'Static Deal',
    [PipelineColumn.rollerDeadline]: 'Roller Deadline (EST)',
    [PipelineColumn.dealTicker]: 'Ticker',
    [PipelineColumn.originatingTransactionClassStatus]: 'Class Status',
    [PipelineColumn.iois]: 'IOIs',
    [PipelineColumn.options]: 'Actions',
    [PipelineColumn.ticker]: 'Ticker (144A)',
    [PipelineColumn.cusip]: 'Identifier 1 (144A)',
    [PipelineColumn.isin]: 'Identifier 2 (144A)',
    [PipelineColumn.tickerRegS]: 'Ticker (Reg S)',
    [PipelineColumn.cusipRegS]: 'Identifier 1 (Reg S)',
    [PipelineColumn.isinRegS]: 'Identifier 2 (Reg S)',
    [PipelineColumn.tickerAccdInvCertif]: 'Ticker (Acc’d Inv./Certif.)',
    [PipelineColumn.cusipAccdInvestor]: 'Identifier 1 (Acc’d Inv./Certif.)',
    [PipelineColumn.isinAccdInvestor]: 'Identifier 2 (Acc’d Inv./Certif.)',
    [PipelineColumn.expectedTiming]: 'Expected',
    [PipelineColumn.dealStage]: 'Deal Stage',
    [PipelineColumn.anchor]: 'Anchor AAA',
    [PipelineColumn.equity]: 'Equity',
    [PipelineColumn.disclosureFile]: 'Disclosure',
    [PipelineColumn.trustee]: 'Trustee',
    [PipelineColumn.managerPresentationFile]: 'Manager Pres.',
    [PipelineColumn.legalNameOnDeal]: 'Deal Name',
    [PipelineColumn.outOfNC]: 'Out of NC',
    [PipelineColumn.outOfRI]: 'Out of RI',
    [PipelineColumn.category]: 'Category',
    [PipelineColumn.static]: 'Static'
};


export const pipelineTooltipText: Partial<Record<PipelineColumn, string>> = {
    [PipelineColumn.nonCallPeriodEnd]:
        'The difference between NC End Date and Closing Date in years. Dashes are displayed when the Closing Date is not specified.',
    [PipelineColumn.reinvestmentPeriodEnd]:
        'The difference between RI End Date and Closing Date in years. Dashes are displayed when the Closing Date is not specified.',
    [PipelineColumn.outOfRI]:
        "'Yes' - if today's date is greater than the date specified in the 'RIE' field. 'No' - if today's date is less or equal than the date specified in the 'RIE' field.  A '-' will be displayed if there is no data for the RIE field.",
    [PipelineColumn.outOfNC]:
        "'Yes' - if today's date is greater than the date specified in the 'NCE' field. 'No' - if today's date is less or equal than the date specified in the 'NCE' field.  A '-' will be displayed if there is no data for the NCE field.",
};
