import moment from "moment";
import { constants } from "./constants";
import { dateTimeUtils, formatUtils, moneyUtils, numericUtils, stringUtils } from "../utils";
import { InventoryPositionStatus } from "../types/inventory/InventoryPositionStatus";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { errorMessages } from "./error.messages";
import { ClassRatingsD, ClassRatingsF, ClassRatingsK, ClassRatingsM, ClassRatingsS, Ratings } from "../types/enums/Rating";
import { AssetTypeList } from "../types/bwic/AssetType";
import { collateralTypesAbbr } from "./collateral-types";
import { TransactionType, TransactionTypes } from "../types/amr-pipeline/enums/TransactionType";
import { equity, equityTitles } from "./amr-pipeline/equity";
import { CollateralType } from "../types/amr-pipeline/enums/CollateralType";
import { arrangerPipelineConstants } from "./amr-pipeline/arrangerPipeline";
import { TrancheStatus } from '../types/amr-pipeline/enums/TrancheStatus';
import { trancheFloaterIndexList } from '../types/amr-pipeline/enums/TrancheFloaterIndex';
import { capitalize, startCase, trim } from 'lodash';
import { amrTrancheStatusesSelectOptions } from "../types/amr-pipeline/enums/AmrTrancheStatus";

export const gridColumns = {
    isinCusip: {
        name: 'isinCusip',
        title: 'Identifier',
        length: 12,
        required: true,
        initialValue: '',
        unique: true,
        pattern: constants.isinCusip,
        cancelOnLeave: true,
        validate: function (value, security) {
            return validateIdentifier(value, security, this.name);
        },
    },
    size: {
        name: 'size',
        title: 'Size',
        type: 'money',
        min: 10000,
        max: 999999999,
        mask: createNumberMask({ prefix: '', integerLimit: 999999999 }),
        pattern: /^\d{1,10}$/,
        length: 11,
        required: true,
        initialValue: '',
        format: formatMoney,
    },
    ticker: {
        name: 'ticker',
        title: 'Ticker',
        length: 32,
        required: true,
        unique: true,
        initialValue: '',
        cancelOnLeave: true,
        validate: function (value, security) {
            return validateIdentifier(value, security, this.name);
        },
    },
    currency: { name: 'currency', title: 'Ccy', required: true, initialValue: '', readonly: true },
    rating: {
        name: 'rating',
        title: 'Rating',
        type: 'select',
        required: true,
        initialValue: '',
        format: value => (value == null ? constants.emptyPlaceholder : value),
        errorMessages: {
            required: errorMessages.ratingIsNotSpecified,
        },
        selectSourceItemsCallback: () => [...Ratings],
    },
    //please do NOT remove white-spaces - there should be 10 chars total!
    asset: {
        name: 'asset',
        title: 'Asset',
        type: 'select',
        required: true,
        initialValue: 'ATCLO     ',
        selectSourceItemsCallback: () => [...AssetTypeList],
    },
    pxTalks: { name: 'pxTalks', title: 'Px Talk', initialValue: [] },
    color: { name: 'color', title: 'Color', initialValue: null },

    company: {
        name: 'company',
        title: 'Broker-Dealer',
        length: 256,
        required: true,
        initialValue: '',
        pattern: constants.nonWhitespace,
    },
    firstName: {
        name: 'firstName',
        title: 'First Name',
        length: 64,
        required: false,
        initialValue: '',
        pattern: constants.contactsName,
    },
    lastName: {
        name: 'lastName',
        title: 'Last Name',
        length: 64,
        required: false,
        initialValue: '',
        pattern: constants.contactsName,
    },
    email: {
        name: 'email',
        title: 'Email',
        length: 256,
        initialValue: '',
        unique: true,
        pattern: /^[a-zA-Z0-9.!#$%&' * +/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    },
    investmentTeamMemberFullName: {
        name: 'fullName',
        title: 'Full Name',
        required: true,
        initialValue: '',
        pattern: constants.fullName,
    },
    investmentTeamMemberTitle: {
        name: 'title',
        title: 'Title',
        required: false,
        initialValue: '',
        pattern: constants.nonWhitespace,
    },
    investmentTeamMemberEmail: {
        name: 'email',
        title: 'Email',
        initialValue: '',
        unique: true,
        pattern: /^[a-zA-Z0-9.!#$%&' * +/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    },
    investmentTeamMemberPhoneNumber: {
        name: 'phoneNumber',
        title: 'Phone',
        initialValue: '',
        pattern: constants.nonWhitespace,
    },
    investmentTeamMemberLinkedin: {
        name: 'linkedIn',
        title: 'LinkedIn',
        required: false,
        initialValue: '',
        pattern: constants.nonWhitespace,
        format: value => formatUtils.formatUrlWithProtocol(value),
    },
    investmentTeamMemberLocation: {
        name: 'location',
        title: 'Location',
        initialValue: '',
        type: 'string',
    },
    bankContactDesk: {
        name: 'primaryDesk',
        title: 'Desk',
        type: 'select',
        required: true,
        initialValue: '',
        errorMessages: {
            required: errorMessages.deskIsNotSpecified,
        },
        selectSourceItemsCallback: () => [
            { key: true, title: 'Primary' },
            { key: false, title: 'Secondary' },
        ],
    },
    bankContactTitle: {
        name: 'title',
        title: 'Title',
        required: false,
        initialValue: '',
        pattern: constants.nonWhitespace,
    },
    bankContactEmail: {
        name: 'email',
        title: 'Email',
        initialValue: '',
        required: false,
        unique: true,
        pattern: /^[a-zA-Z0-9.!#$%&' * +/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    },
    bankContactOfficeNumber: {
        name: 'officePhoneNumber',
        title: 'Office',
        initialValue: '',
        required: false,
        pattern: constants.nonWhitespace,
    },
    bankContactMobileNumber: {
        name: 'mobilePhoneNumber',
        title: 'Mobile',
        initialValue: '',
        required: false,
        pattern: constants.nonWhitespace,
    },
    bankContactLinkedin: {
        name: 'linkedIn',
        title: 'LinkedIn',
        required: false,
        initialValue: '',
        pattern: constants.nonWhitespace,
        format: value => formatUtils.formatUrlWithProtocol(value),
    },
    meetingContact: {
        name: 'meetingContact',
        title: 'Meeting Contact',
        type: 'select',
        keepEmptyOption: false,
        selectSourceItemsCallback: () => [
            { key: true, title: 'Yes' },
            { key: false, title: 'No' },
        ],
    },
    role: { name: 'role', title: 'Title', length: 256, initialValue: '', pattern: constants.nonWhitespace },
    phone: { name: 'phone', title: 'Phone', length: 20, initialValue: '', pattern: constants.nonWhitespace },
    bid: {
        name: 'bid',
        title: 'Bid, %',
        titleForError: 'Bid',
        type: 'number',
        min: 0.001,
        max: 150.0,
        length: 8,
        required: false,
        initialValue: '',
        disabledSort: true,
        pattern: /^\d{0,3}(\.\d{0,3})?$/,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 3,
        }),
        format: value => formatDecimal(value, 3),
    },
    offer: {
        name: 'offer',
        title: 'Offer, %',
        titleForError: 'Offer',
        type: 'number',
        min: 0.001,
        max: 150.0,
        length: 8,
        required: true,
        initialValue: '',
        disabledSort: true,
        pattern: /^\d{0,3}(\.\d{0,3})?$/,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 3,
        }),
        format: value => formatDecimal(value, 3),
    },
    creditEnhancement: {
        name: 'creditEnhancement',
        title: 'C/E, %',
        titleForError: 'C/E',
        type: 'number',
        min: 0.0001,
        max: 100,
        length: 8,
        required: false,
        initialValue: '',
        disabledSort: true,
        pattern: /^\d{0,3}(\.\d{0,4})?$/,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 4,
        }),
        format: value => formatDecimal(value, 4),
    },
    bidSize: {
        name: 'bidSize',
        title: 'Bid Size, MM',
        titleForError: 'Bid Size',
        type: 'number',
        min: 0.01,
        max: 999.999,
        length: 7,
        required: false,
        initialValue: '',
        mask: createNumberMask({ prefix: '', decimalLimit: 3, integerLimit: 3, allowDecimal: true }),
        pattern: /^\d{0,3}(\.\d{0,3})?$/,
        format: value => formatDecimal(value, 3),
        disabledSort: true
    },
    offerSize: {
        name: 'size',
        title: 'Offer Size, MM',
        titleForError: 'Offer Size',
        type: 'number',
        min: 0.01,
        max: 999.999,
        length: 7,
        required: true,
        initialValue: '',
        mask: createNumberMask({ prefix: '', decimalLimit: 3, integerLimit: 3, allowDecimal: true }),
        pattern: /^\d{0,3}(\.\d{0,3})?$/,
        format: value => formatDecimal(value, 3),
        disabledSort: true
    },
    bidDmBps: {
        name: 'bidDmBps',
        title: 'Bid DM, bps',
        titleForError: 'Bid DM',
        type: 'integer',
        min: 0,
        max: 10000,
        length: 5,
        required: false,
        initialValue: '',
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            includeThousandsSeparator: false,
            integerLimit: 5,
        }),
        pattern: /^\d{1,5}$/,
        format: value => {
            const offer = numericUtils.numberOrDefault(value, null);
            return offer == null ? value : String(Math.trunc(offer));
        },
    },
    offerDmBps: {
        name: 'offerDmBps',
        title: 'Offer DM, bps',
        titleForError: 'Offer DM',
        type: 'integer',
        min: 0,
        max: 10000,
        length: 5,
        required: false,
        initialValue: '',
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            includeThousandsSeparator: false,
            integerLimit: 5,
        }),
        pattern: /^\d{1,5}$/,
        format: value => {
            const offer = numericUtils.numberOrDefault(value, null);
            return offer == null ? value : String(Math.trunc(offer));
        },
    },
    offerYld: {
        name: 'offerYield',
        title: 'Yld, %',
        titleForError: 'Yld',
        type: 'number',
        min: 0.01,
        max: 100,
        length: 8,
        required: false,
        initialValue: '',
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 2,
        }),
        pattern: /^(\d{0,3}(\.\d{0,2})?)?$/,
        format: formatDecimal,
    },
    spread: {
        name: 'spread',
        title: 'Spread, %',
        titleForError: 'Spread',
        type: 'number',
        min: 0.01,
        max: 100,
        length: 6,
        required: false,
        initialValue: '',
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 2,
        }),
        pattern: /^(\d{0,3}(\.\d{0,2})?)?$/,
        format: formatDecimal,
    },
    walYears: {
        name: 'walYears',
        title: 'WAL',
        type: 'number',
        min: 0.01,
        max: 999.99,
        length: 6,
        required: false,
        initialValue: '',
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 2,
        }),
        pattern: /^(\d{0,3}(\.\d{0,2})?)?$/,
        format: formatDecimal,
    },
    mvoc: {
        name: 'mvoc',
        title: 'MVOC, %',
        titleForError: 'MVOC',
        type: 'number',
        min: 0.01,
        max: 999.99,
        length: 6,
        required: false,
        initialValue: '',
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 2,
        }),
        pattern: /^(\d{0,3}(\.\d{0,2})?)?$/,
        format: formatDecimal,
    },
    manager: {
        name: 'manager',
        title: 'Manager',
        type: 'longText',
        length: 80,
        required: false,
        initialValue: '',
        disabledSort: true,
    },
    notes: {
        name: 'description',
        title: 'Note',
        type: 'longText',
        length: 64,
        required: false,
        initialValue: '',
        disabledSort: true,
    },
    coupon: { name: 'coupon', title: 'Coupon', initialValue: '', readonly: true, disabledSort: true },
    ncEnd: {
        name: 'ncEnd',
        title: 'NC End, mm/yy',
        titleForError: 'NC End',
        length: 5,
        required: false,
        initialValue: '',
        disabledSort: true,
        mask: [/[0,1]{1}/, /\d/, '/', /\d/, /\d/],
        pattern: /^([0,1]{1}\d\/\d\d)?$/,
        validate: validateMonthAndYear,
        format: raw => formatUtils.formatMonthAndYear(raw, raw),
    },
    riEnd: {
        name: 'riEnd',
        title: 'RI End, mm/yy',
        titleForError: 'RI End',
        length: 5,
        required: false,
        initialValue: '',
        disabledSort: true,
        mask: [/[0,1]{1}/, /\d/, '/', /\d/, /\d/],
        pattern: /^([0,1]{1}\d\/\d\d)?$/,
        validate: validateMonthAndYear,
        format: raw => formatUtils.formatMonthAndYear(raw, raw),
    },
    floaterIndex: {
        name: 'floaterIndex',
        type: 'select',
        title: 'Floater Index',
        required: false,
        initialValue: '',
        disabledSort: true,
    },
    percentOfTrade: {
        name: 'percent',
        title: '% of Trade',
        type: 'number',
        length: 6,
        min: 0,
        max: 100,
        required: true,
        initialValue: '',
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 2,
        }),
        pattern: /^(\d{0,3}(\.\d{0,2})?)?$/,
        format: formatDecimal,
    },
    amountOfTrade: {
        name: 'amount',
        title: 'Amount of Trade',
        type: 'money',
        length: 15,
        min: 0,
        max: 999999999,
        required: true,
        initialValue: '',
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: true,
            integerLimit: 9,
            decimalLimit: 3,
        }),
        pattern: /^(\d{0,9}(\.\d{0,3})?)?$/,
        format: value => formatMoney(value, 3),
    },
    proceeds: {
        name: 'proceed',
        title: 'Proceeds',
        type: 'money',
        length: 15,
        min: 0,
        max: 999999999,
        required: false,
        disabledSort: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: true,
            integerLimit: 9,
            decimalLimit: 3,
        }),
        pattern: /^(\d{0,9}(\.\d{0,3})?)?$/,
        format: function (value) {
            if (this.readonly && stringUtils.isNullOrWhiteSpace(value)) {
                return '-';
            }
            return formatMoney(value, 3);
        },
    },
    settlementAccount: {
        name: 'settlementAccountId',
        title: 'Settlement Account',
        type: 'select',
        required: true,
        disabledSort: true,
        unique: true,
    },
    classReferenceName: {
        name: 'classReferenceName',
        title: 'Class',
        type: 'select',
        length: 64,
        required: true,
    },
    ioiSize: {
        name: 'size',
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: true,
            decimalLimit: 2,
        }),
        title: 'IOI Size',
        type: 'money',
        min: 250000,
        validate: validateIOISize,
        required: true,
        format: value => moneyUtils.money(value) || value,
    },
    oid: {
        name: 'oid',
        title: 'OID',
        min: 0,
        max: 150,
        type: 'number',
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            decimalLimit: 4,
            integerLimit: 3,
            includeThousandsSeparator: false,
        }),
        format: value => formatDecimal(value, 4),
    },
    dm: {
        name: 'dm',
        title: 'DM, bps',
        titleForError: 'DM',
        type: 'integer',
        min: 0,
        max: 10000,
        length: 5,
        required: true,
        initialValue: '',
        mask: createNumberMask({
            prefix: '',
            includeThousandsSeparator: false,
            integerLimit: 5,
        }),
        pattern: /^\d{1,5}$/,
        format: value => {
            const offer = numericUtils.numberOrDefault(value, null);
            return offer == null ? value : Math.trunc(offer);
        },
        validate: function (value) {
            const offer = numericUtils.numberOrDefault(value, null);

            if (offer < this.min || offer > this.max) {
                return `${this.titleForError} should be in the range of ${this.min} to ${moneyUtils.money(this.max)}`;
            }
        },
    },
    ioiFloaterIndex: {
        name: 'floaterIndex',
        required: true,
        type: 'select',
        title: 'Floater Index',
        initialValue: '',
        disabledSort: true,
    },
    comment: {
        name: 'comment',
        title: 'Comment',
        type: 'longText',
        length: 1024,
        initialValue: '',
    },
    balance: {
        name: 'balance',
        title: 'Balance',
        type: 'money',
        readonly: true,
        format: value => moneyUtils.money(value) || value,
    },
    ioiRatings: {
        name: 'ioiRatings',
        title: 'Rtg (M/S/F/K/D)',
        type: 'string',
        readonly: true,
    },
    guidance: {
        name: 'guidance',
        title: 'Guidance',
        type: 'string',
        length: 25,
        readonly: true,
        format: value => value ?? constants.emptyPlaceholder,
    },
    subscription: {
        name: 'subscription',
        title: 'Subscription',
        type: 'string',
        length: 128,
        readonly: true,
        format: value => value ?? constants.emptyPlaceholder,
    },
    submitDate: {
        name: 'submitDate',
        title: 'Date Submitted',
        type: 'string',
        readonly: true,
        format: date => (date ? moment(date).format(constants.dateTimeFormat) : constants.emptyPlaceholder),
    },

    margin: {
        initialValue: null,
        name: 'margin',
        title: 'Margin, %',
        titleForError: 'Margin',
        disabledSort: false,
        type: 'number',
        min: 0.01,
        max: 100,
        length: 6,
        required: false,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 2,
        }),
        pattern: /^(\d{0,3}(\.\d{0,2})?)?$/,
        format: formatDecimal,
    },

    arranger: {
        name: 'arrangerReferenceName',
        title: 'Arranger',
        type: 'select',
        required: true,
        initialValue: '',
        disabledSort: true,
    },

    dealName: {
        name: 'dealLegalName',
        title: 'Deal Name',
        type: 'lookup',
        required: true,
        initialValue: '',
        disabledSort: true,
        disableUppercase: true,
    },
    dealLegalName: {
        name: 'dealLegalName',
        title: 'Deal Name',
        type: 'string',
        readonly: true,
        required: false,
        format: value => value || constants.emptyPlaceholder,
    },
    collateralManager: {
        name: 'collateralManagerReferenceName',
        title: 'Manager',
        type: 'select',
        required: true,
        disabledSort: true,
        initialValue: '',
    },
    collateralManagerReferenceName: {
        name: 'collateralManagerReferenceName',
        title: 'Manager',
        type: 'string',
        required: false,
        readonly: true,
        disabledSort: true,
    },
    transactionType: {
        name: 'type',
        title: 'Type',
        type: 'select',
        disabledSort: true,
        selectSourceItemsCallback: () =>
            TransactionTypes.filter(type => type.value !== TransactionType.amr).map(t => ({
                key: t.value,
                title: t.text,
            })),
    },
    collateralType: {
        name: 'collateralType',
        title: 'Collat.',
        type: 'select',
        initialValue: '',
        disabledSort: true,
        selectSourceItemsCallback: () => [
            { key: CollateralType.broadlySyndicated, title: collateralTypesAbbr[CollateralType.broadlySyndicated] },
            { key: CollateralType.middleMarket, title: collateralTypesAbbr[CollateralType.middleMarket] },
        ],
    },

    expectedTiming: {
        name: 'expectedTiming',
        title: 'Expected',
        length: 256,
        disabledSort: true,
    },

    nonCallPeriodEnd: {
        name: 'nonCallPeriodEnd',
        title: 'NCE, yrs',
        type: 'number',
        min: 0,
        max: 10,
        disabledSort: true,
        format: formatDecimal,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 2,
            decimalLimit: 2,
        }),
    },

    nonCallPeriodEndDate: {
        name: 'nonCallPeriodEnd',
        title: 'NCE',
        type: 'string',
        readonly: true,
        required: false,
        format: value => value ? moment(value).format(constants.dateFormat) : constants.emptyPlaceholder,
    },
    reinvestmentPeriodEnd: {
        name: 'reinvestmentPeriodEnd',
        title: 'RIE, yrs',
        type: 'number',
        min: 0,
        max: 10,
        disabledSort: true,
        format: formatDecimal,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 2,
            decimalLimit: 2,
        }),
    },
    reinvestmentPeriodEndDate: {
        name: 'reinvestmentPeriodEnd',
        title: 'RIE',
        type: 'string',
        disabledSort: true,
        readonly: true,
        required: false,
        format: value => value ? moment(value).format(constants.dateFormat) : constants.emptyPlaceholder,
    },
    dealStage: {
        name: 'dealStage',
        title: 'Deal Stage',
        type: 'select',
        initialValue: '',
        disabledSort: true,
        keepEmptyOption: true,
        selectSourceItemsCallback: () => ['Mandating', 'Warehousing'],
    },

    euCompliance: {
        name: 'euCompliance',
        title: 'EU Compliance',
        type: 'select',
        disabledSort: true,
        initialValue: null,
        selectSourceItemsCallback: () => [
            { key: true, title: 'Yes' },
            { key: false, title: 'No' },
        ],
    },

    anchor: {
        name: 'anchor',
        title: 'Anchor AAA',
        type: 'select',
        initialValue: null,
        disabledSort: true,
        selectSourceItemsCallback: () => [
            { key: true, title: 'Yes' },
            { key: false, title: 'No' },
        ],
    },

    equity: {
        name: 'equity',
        title: 'Equity',
        initialValue: '',
        type: 'select',
        disabledSort: true,
        selectSourceItemsCallback: () => equity.map(e => ({ key: e, title: equityTitles[e] })),
    },

    disclosure: {
        name: 'disclosureFile',
        title: 'Disclosure',
        initialValue: '',
        type: 'file',
        readonlyCallback: arrangerPipeline =>
            !(
                arrangerPipeline.collateralManagerReferenceName ||
                arrangerPipeline.dealLegalName ||
                arrangerPipeline.arrangerReferenceName
            ),
        file: {
            maxSize: arrangerPipelineConstants.maxFileSize,
            acceptedTypes: arrangerPipelineConstants.acceptedFileTypes,
        },
        disabledSort: true,
    },

    yrsAtFirm: {
        name: 'yrsAtCompany',
        title: 'Yrs at firm',
        type: 'number',
        min: 0,
        disabledSort: true,
        format: value => formatDecimal(value, 0),
        mask: createNumberMask({
            prefix: '',
            allowDecimal: false,
            includeThousandsSeparator: false,
            integerLimit: 2,
        }),
    },

    yrsExperience: {
        name: 'yrsExperience',
        title: 'Yrs experience',
        type: 'number',
        min: 0,
        disabledSort: true,
        format: value => formatDecimal(value, 0),
        mask: createNumberMask({
            prefix: '',
            allowDecimal: false,
            includeThousandsSeparator: false,
            integerLimit: 2,
        }),
    },

    dealsName: {
        name: 'name',
        title: 'Class',
        required: true,
        type: 'string',
    },

    dealsStatus: {
        name: 'status',
        title: 'Status',
        type: 'select',
        selectSourceItemsCallback: () => [
            { key: TrancheStatus.Active, title: TrancheStatus.Active },
            { key: TrancheStatus.Draft, title: TrancheStatus.Draft },
            { key: TrancheStatus.Inactive, title: TrancheStatus.Inactive },
        ],
    },

    dealsTransactionClosingDate: {
        name: 'transactionClosingDate',
        title: 'Closing',
        readonly: true,
        type: 'string',
        format: (value) => formatUtils.formatDate(value),
    },

    dealsTransactionType: {
        name: 'transactionType',
        title: 'Trans.Type',
        readonly: true,
        type: 'string',
        format: startCase,
    },

    dealsTicker144A: {
        name: 'ticker144A',
        title: 'Ticker (Rule 144A)',
        type: 'string',
        disabledSort: true,
        format: trim
    },

    dealsCusip144A: {
        name: 'cusip144A',
        title: 'Identifier 1 (Rule 144A)',
        type: 'string',
        validate: (value) => {
            if (!value) {
                return '';
            }

            if (value.length !== constants.cusipLength) {
                return errorMessages.invalidCusipLength('Rule 144A');
            }
        },
        disabledSort: true,
        format: trim
    },

    dealsIsin144A: {
        name: 'isin144A',
        title: 'Identifier 2 (Rule 144A)',
        type: 'string',
        validate: (value) => {
            if (!value) {
                return '';
            }

            if (value.length !== constants.isinLength) {
                return errorMessages.invalidIsinLength('Rule 144A');
            }
        },
        disabledSort: true,
        format: trim,
    },

    dealsTickerRegS: {
        name: 'tickerRegS',
        title: 'Ticker (Reg S)',
        type: 'string',
        disabledSort: true,
        format: trim
    },

    dealsCusipRegS: {
        name: 'cusipRegS',
        title: 'Identifier 1 (Reg S)',
        type: 'string',
        validate: (value) => {
            if (!value) {
                return '';
            }

            if (value.length !== constants.cusipLength) {
                return errorMessages.invalidCusipLength('Reg S');
            }
        },
        disabledSort: true,
        format: trim
    },

    dealsIsinRegS: {
        name: 'isinRegS',
        title: 'Identifier 2 (Reg S)',
        type: 'string',
        validate: (value) => {
            if (!value) {
                return '';
            }

            if (value.length !== constants.isinLength) {
                return errorMessages.invalidIsinLength('Reg S');
            }
        },
        disabledSort: true,
        format: trim
    },

    dealsCommonCodeRegS: {
        name: 'commonCodeRegS',
        title: 'Common Code (Reg S)',
        type: 'string',
        disabledSort: true,
        format: trim
    },

    dealsTickerAccdInvCertif: {
        name: 'tickerAccdInvCertif',
        title: 'Ticker (Acc’d Inv/Certif.)',
        type: 'string',
        disabledSort: true,
        format: trim
    },

    dealsCusipAccdInvCertif: {
        name: 'cusipAccdInvCertif',
        title: 'Identifier 1 (Acc’d Inv/Certif.)',
        type: 'string',
        validate: (value) => {
            if (!value) {
                return '';
            }

            if (value.length !== constants.cusipLength) {
                return errorMessages.invalidCusipLength('Acc’d Inv/Certif.');
            }
        },
        disabledSort: true,
        format: trim
    },

    dealsIsinAccdInvCertif: {
        name: 'isinAccdInvCertif',
        title: 'Identifier 2 (Acc’d Inv/Certif.)',
        type: 'string',
        validate: (value) => {
            if (!value) {
                return '';
            }

            if (value.length !== constants.isinLength) {
                return errorMessages.invalidIsinLength('Acc’d Inv/Certif.');
            }
        },
        disabledSort: true,
        format: trim
    },

    dealsOriginalBalance: {
        name: 'originalBalance',
        title: 'Orig. Balance',
        type: 'money',
        min: 0,
        mask: createNumberMask({ prefix: '' }),
        pattern: /^\d{1,10}$/,
        length: 11,
        initialValue: '',
        format: formatMoney,
        disabledSort: true
    },

    dealsBalance: {
        name: 'balance',
        title: 'Cur. Balance',
        type: 'money',
        min: 0,
        mask: createNumberMask({ prefix: '' }),
        pattern: /^\d{1,10}$/,
        length: 11,
        initialValue: '',
        format: formatMoney,
        disabledSort: true
    },

    dealsOriginalMargin: {
        name: 'originalMargin',
        title: 'Orig. Margin, %',
        type: 'number',
        length: 7,
        required: false,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 3,
        }),
        pattern: /^(\d{0,3}(\.\d{0,3})?)?$/,
        format: (value) => formatUtils.formatMargin(value),
        disabledSort: true
    },

    dealsMargin: {
        name: 'margin',
        title: 'Cur. Margin, %',
        type: 'number',
        length: 7,
        required: false,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 3,
        }),
        pattern: /^(\d{0,3}(\.\d{0,3})?)?$/,
        format: (value) => formatUtils.formatMargin(value),
        disabledSort: true,
    },

    dealsOriginalFloaterIndex: {
        name: 'originalFloaterIndex',
        title: 'Orig. Floater Index',
        type: 'select',
        selectSourceItemsCallback: () => trancheFloaterIndexList,
        disabledSort: true
    },

    dealsFloaterIndex: {
        name: 'floaterIndex',
        title: 'Cur. Floater Index',
        type: 'select',
        selectSourceItemsCallback: () => trancheFloaterIndexList,
        disabledSort: true
    },

    dealsOriginalCoupon: {
        name: 'originalCoupon',
        title: 'Orig. Coupon',
        readonly: true,
        type: 'string',
        disabledSort: true
    },

    dealsCoupon: {
        name: 'coupon',
        title: 'Cur. Coupon',
        readonly: true,
        type: 'string',
        disabledSort: true
    },

    dealsMinimumDenomination: {
        name: 'minimumDenomination',
        title: 'Min. Denomination',
        type: 'money',
        mask: createNumberMask({ prefix: '' }),
        pattern: /^\d{1,10}$/,
        length: 11,
        initialValue: '',
        format: formatMoney,
        disabledSort: true
    },

    dealsType: {
        name: 'type',
        title: 'Type',
        type: 'string',
        disabledSort: true
    },

    dealsFactor: {
        name: 'factor',
        title: 'Factor',
        readonly: true,
        type: 'string',
        format: (value) => formatUtils.formatDecimal(value, 4),
        disabledSort: true
    },

    dealsEquity: {
        name: 'equity',
        title: 'Equity',
        type: 'select',
        required: true,
        initialValue: null,
        keepEmptyOption: false,
        selectSourceItemsCallback: () => [
            { key: true, title: 'Yes' },
            { key: false, title: 'No' },
        ],
        disabledSort: true
    },

    dealsRedeemed: {
        name: 'redeemed',
        title: 'Redeemed',
        readonly: true,
        type: 'string',
        format: (value) => value == null ? '' : capitalize(formatUtils.formatBooleanWithPlaceholder(value)),
        disabledSort: true
    },

    dealsAmr: {
        name: 'amr',
        title: 'AMR Class',
        type: 'select',
        required: true,
        initialValue: null,
        keepEmptyOption: false,
        selectSourceItemsCallback: () => [
            { key: true, title: 'Yes' },
            { key: false, title: 'No' },
        ],
        disabledSort: true
    },

    dealsNonAmrPeriod: {
        name: 'nonAmrPeriod',
        title: 'Non-AMR',
        type: 'number',
        initialValue: 0,
        required: true,
        min: 0,
        max: 100,
        format: (value) => value ? formatUtils.formatDecimal(value, 0) : '',
        mask: createNumberMask({
            prefix: '',
            allowDecimal: false,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 0,
        }),
        disabledSort: true
    },

    dealsNonAmrPeriodEnd: {
        name: 'nonAmrPeriodEnd',
        title: 'Non-AMR Period End',
        type: 'date',
        disabledSort: true
    },

    dealsOriginalRatingMoodys: {
        name: 'originalRatingMoodys',
        title: 'Orig. Rtg (M)',
        type: 'select',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsM,
        disabledSort: true
    },

    dealsOriginalRatingSnP: {
        name: 'originalRatingSnP',
        title: 'Orig. Rtg (S)',
        type: 'select',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsS,
        disabledSort: true
    },

    dealsOriginalRatingFitch: {
        name: 'originalRatingFitch',
        title: 'Orig. Rtg (F)',
        type: 'select',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsF,
        disabledSort: true
    },

    dealsOriginalRatingKbra: {
        name: 'originalRatingKbra',
        title: 'Orig. Rtg (K)',
        type: 'select',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsK,
        disabledSort: true
    },

    dealsOriginalRatingDbrs: {
        name: 'originalRatingDbrs',
        title: 'Orig. Rtg (D)',
        type: 'select',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsD,
        disabledSort: true
    },

    dealsRatingMoodys: {
        name: 'ratingMoodys',
        title: 'Cur. Rtg (M)',
        type: 'select',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsM,
        disabledSort: true
    },

    dealsRatingSnP: {
        name: 'ratingSnP',
        title: 'Cur. Rtg (S)',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        type: 'select',
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsS,
        disabledSort: true
    },

    dealsRatingFitch: {
        name: 'ratingFitch',
        title: 'Cur. Rtg (F)',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        type: 'select',
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsF,
        disabledSort: true
    },

    dealsRatingKbra: {
        name: 'ratingKbra',
        title: 'Cur. Rtg (K)',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        type: 'select',
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsK,
        disabledSort: true
    },

    dealsRatingDbrs: {
        name: 'ratingDbrs',
        title: 'Cur. Rtg (D)',
        required: false,
        initialValue: '',
        keepEmptyOption: true,
        type: 'select',
        format: value => (value == null ? constants.emptyPlaceholder : value),
        selectSourceItemsCallback: () => ClassRatingsD,
        disabledSort: true
    },

    dealsCurrencyCode: {
        name: 'currencyCode',
        title: 'Currency',
        type: 'string',
        readonly: true,
        disabledSort: true,
    },

    dealsPariPassuTranches: {
        name: 'pariPassuTranches',
        title: 'Pari-Passu Classes',
        type: 'string',
        disabledSort: true
    },

    dealsTrancheSupported: {
        name: 'trancheSupported',
        title: 'Class Supported',
        type: 'string',
        disabledSort: true
    },

    dealsTransactionReferenceAndClass: {
        name: 'linkedTransactionReferenceAndClass',
        title: 'Trans. Reference & Class',
        initialValue: '',
        keepEmptyOption: true,
        type: 'select',
        disabledSort: true
    },

    auctionStatus: {
        name: 'status',
        title: 'Auction Status',
        type: 'select',
        selectSourceItemsCallback: () => amrTrancheStatusesSelectOptions,
        disabledSort: true,
        required: true,
    },


    capMargin: {
        name: 'capMargin',
        title: 'Cap Margin, %',
        type: 'number',
        min: 0.01,
        max: 100,
        length: 6,
        required: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 3,
        }),
        pattern: /^(\d{0,3}(\.\d{0,3})?)?$/,
        format: (value) => formatUtils.formatMargin(value),
        disabledSort: true,
    },
    minAmount: {
        name: 'minBidAmount',
        title: 'Min Amount',
        type: 'money',
        mask: createNumberMask({ prefix: '' }),
        pattern: /^\d{1,10}$/,
        initialValue: '',
        format: formatMoney,
        disabledSort: true,
        required: true,
    },
    stepAmount: {
        name: 'principalAmountStep',
        title: 'Step Amount',
        type: 'money',
        min: 1,
        mask: createNumberMask({ prefix: '' }),
        pattern: /^\d{1,10}$/,
        length: 11,
        initialValue: '',
        format: formatMoney,
        disabledSort: true,
        required: true,
    },
    redemptionPrice: {
        name: 'redemptionPriceInPercent',
        title: 'Redemption Price',
        type: 'number',
        min: 100,
        initialValue: '',
        format: value => formatDecimal(value, 6),
        disabledSort: true,
        required: true,
    },
    amrPricing: {
        name: 'pricingDate',
        title: 'AMR Pricing',
        type: 'date',
        disabledSort: true,
        required: true,
    },
    amrSettlement: {
        name: 'settlementDate',
        title: 'AMR Settlement',
        type: 'date',
        disabledSort: true,
        required: true,
    },

    arrangerPipeline: function () {
        return [
            this.arranger,
            this.dealName,
            this.collateralManager,
            this.transactionType,
            this.collateralType,
            this.nonCallPeriodEnd,
            this.reinvestmentPeriodEnd,
            this.expectedTiming,
            this.dealStage,
            this.euCompliance,
            this.anchor,
            this.equity,
            this.disclosure,
        ];
    },

    iois: function () {
        return [
            this.classReferenceName,
            this.ioiSize,
            this.dm,
            this.oid,
            this.ioiFloaterIndex,
            this.margin,
            this.comment,
            this.submitDate,
            this.balance,
            this.ioiRatings,
            this.guidance,
            this.subscription,
        ];
    },

    contacts: function () {
        return [this.firstName, this.lastName, this.email, this.company, this.role, this.phone];
    },

    securities: function () {
        return [
            this.isinCusip,
            this.size,
            this.ticker,
            this.currency,
            this.rating,
            //this.asset, //temporary disable this column till there will be more than 2 values
            this.pxTalks,
            this.color,
        ];
    },
    securitiesWithoutPxTalkAndColor: function () {
        return [
            this.isinCusip,
            this.size,
            this.ticker,
            this.currency,
            this.rating,
            //this.asset  //temporary disable this column till there will be more than 2 values
        ];
    },
    inventory: function () {
        return [
            withInventoryReadonlyCallback({ ...this.isinCusip, disabledSort: true }),
            withInventoryReadonlyCallback({ ...this.ticker, disabledSort: true }),
            withInventoryReadonlyCallback({ ...this.rating, disabledSort: true }),
            withInventoryReadonlyCallback(this.offerSize),
            withInventoryReadonlyCallback(this.offer),
            withInventoryReadonlyCallback(this.bidSize),
            withInventoryReadonlyCallback(this.bid),
            withInventoryReadonlyCallback(this.creditEnhancement),
            withInventoryReadonlyCallback(this.offerDmBps),
            withInventoryReadonlyCallback(this.bidDmBps),
            withInventoryReadonlyCallback(this.offerYld),
            withInventoryReadonlyCallback(this.floaterIndex),
            withInventoryReadonlyCallback(this.spread),
            withInventoryReadonlyCallback(this.coupon),
            withInventoryReadonlyCallback(this.walYears),
            withInventoryReadonlyCallback(this.mvoc),
            withInventoryReadonlyCallback({ ...this.dealLegalName, disabledSort: true }),
            withInventoryReadonlyCallback({
                ...this.collateralManagerReferenceName,
                format: value => value || constants.emptyPlaceholder,
            }),
            withInventoryReadonlyCallback({ ...this.nonCallPeriodEndDate, disabledSort: true }),
            withInventoryReadonlyCallback(this.reinvestmentPeriodEndDate),
            withInventoryReadonlyCallback(this.notes),
        ];
    },

    cloManagersInvestmentTeam: function () {
        return [
            this.investmentTeamMemberFullName,
            this.investmentTeamMemberLinkedin,
            this.investmentTeamMemberTitle,
            this.investmentTeamMemberEmail,
            this.investmentTeamMemberPhoneNumber,
            this.investmentTeamMemberLocation,
            this.meetingContact,
            // AMR-8924 CLO Managers:: CLO Team:: Hide the 'Yrs at firm' and 'Yrs experience' fields in the table
            // this.yrsAtFirm,
            // this.yrsExperience,
        ];
    },

    banksContacts: function () {
        return [
            this.firstName,
            this.lastName,
            this.bankContactDesk,
            this.bankContactLinkedin,
            this.bankContactTitle,
            this.bankContactEmail,
            this.bankContactOfficeNumber,
            this.bankContactMobileNumber
        ];
    },

    dealsAllClasses: function() {
        return [
            this.dealsName,
            this.dealsStatus,
            this.dealsTransactionClosingDate,
            this.dealsTransactionType,
            this.dealsTicker144A,
            this.dealsCusip144A,
            this.dealsIsin144A,
            this.dealsTickerRegS,
            this.dealsCusipRegS,
            this.dealsIsinRegS,
            this.dealsCommonCodeRegS,
            this.dealsTickerAccdInvCertif,
            this.dealsCusipAccdInvCertif,
            this.dealsIsinAccdInvCertif,
            this.dealsOriginalBalance,
            this.dealsBalance,
            this.dealsOriginalMargin,
            this.dealsMargin,
            this.dealsOriginalFloaterIndex,
            this.dealsFloaterIndex,
            this.dealsOriginalCoupon,
            this.dealsCoupon,
            this.dealsMinimumDenomination,
            this.dealsType,
            this.dealsFactor,
            this.dealsEquity,
            this.dealsRedeemed,
            this.dealsAmr,
            this.dealsNonAmrPeriod,
            this.dealsNonAmrPeriodEnd,
            this.dealsOriginalRatingMoodys,
            this.dealsOriginalRatingSnP,
            this.dealsOriginalRatingFitch,
            this.dealsOriginalRatingKbra,
            this.dealsOriginalRatingDbrs,
            this.dealsRatingMoodys,
            this.dealsRatingSnP,
            this.dealsRatingFitch,
            this.dealsRatingKbra,
            this.dealsRatingDbrs,
            this.dealsCurrencyCode,
            this.dealsPariPassuTranches,
            this.dealsTrancheSupported,
        ];
    },

    dealsAmrClasses: function() {
        return [
            {...this.dealsName, readonly: true, disabledSort: true},
            this.auctionStatus,
            {...this.balance, disabledSort: true},
            {...this.dealsMargin, readonly: true, pattern: null},
            this.capMargin,
            {...this.balance, title: 'Max Amount', disabledSort: true},
            this.minAmount,
            this.stepAmount,
            this.redemptionPrice,
            this.amrPricing,
            this.amrSettlement,
            this.dealsNonAmrPeriod
        ]
    }
};

export function createCompanyBobColumn(companyCode, validate, headerError) {
    return {
        name: companyCode,
        title: companyCode,
        titleForError: 'Bid',
        headerError,
        className: 'cell-companyCode',
        type: 'number',
        required: false,
        min: constants.bidRange.min,
        max: constants.bidRange.max,
        length: 8,
        initialValue: '',
        disabledSort: true,
        canRemove: true,
        mask: createNumberMask({
            prefix: '',
            allowDecimal: true,
            includeThousandsSeparator: false,
            integerLimit: 3,
            decimalLimit: 4
        }),
        pattern: /^\d{0,3}(\.\d{0,4})?$/,
        format: value => numericUtils.isNumber(value) ? formatUtils.formatBid(Number(value)) : value,
        validate
    }
}

function formatMoney(value, decimalPlaces = 0) {
    const numericValue = typeof value === 'number' ? value : moneyUtils.parse(value);
    if (!numericUtils.isNumber(numericValue)) {
        return value;
    }

    return moneyUtils.money(numericValue, Boolean(decimalPlaces), decimalPlaces);
}

function formatDecimal(value, decimalPlaces = 2, defaultValue = '') {
    const number = numericUtils.numberOrDefault(value, NaN);
    const result  = isNaN(number) ? String(defaultValue) : formatUtils.formatDecimal(number, decimalPlaces);
    return result ?? String(defaultValue);
}

function validateMonthAndYear(value) {
    if (value) {
        const { isMonthValid, isYearValid } = dateTimeUtils.parseMonthAndYearStamp(value);

        if (!isMonthValid && !isYearValid) return errorMessages.invalidValue;
        if (!isMonthValid) return 'Month is invalid';
        if (!isYearValid) return 'Year is invalid';
    }

    return '';
}

function validateIdentifier(identifier, security, columnName) {
    if (!security.securityId && identifier) {
        return errorMessages.identifierNotFount;
    }

    return '';
}

function withInventoryReadonlyCallback(column) {
    return {
        ...column,
        readonlyCallback: inventoryPosition => inventoryPosition.status === InventoryPositionStatus.traded
    }
}

function validateIOISize(value, { balance }) {
    const minIoISize = gridColumns.ioiSize.min;

    if (value > Number(balance) || value < Number(minIoISize)) {
        return `Size should be in the range of ${gridColumns.ioiSize.format(
            minIoISize,
        )} to ${gridColumns.balance.format(balance)}`;
    }
}
