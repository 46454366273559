import { ColumnConfigKey } from "../types/page-config/column-customizer/ColumnConfigKey";

export const bwicColumnsLabels: { [key in ColumnConfigKey]: string } = {
    [ColumnConfigKey.IsinCusip]: 'Identifier',
    [ColumnConfigKey.Ticker]: 'Ticker',
    [ColumnConfigKey.Inventory]: 'Inventory',
    [ColumnConfigKey.Asset]: 'Asset',
    [ColumnConfigKey.Currency]: 'Ccy',
    [ColumnConfigKey.Rating]: 'Rtg',
    [ColumnConfigKey.Size]: 'Size, MM',
    [ColumnConfigKey.PxTalk]: 'Px Talk',
    [ColumnConfigKey.Color]: 'Color',
    [ColumnConfigKey.DealName]: 'Deal Name',
    [ColumnConfigKey.CollateralManager]: 'Manager',
    [ColumnConfigKey.ClosingDate]: 'Closing',
    [ColumnConfigKey.NcEnd]: 'NCE',
    [ColumnConfigKey.NcEndYrs]: 'NCE, yrs',
    [ColumnConfigKey.OutOfNc]: 'Out of NC',
    [ColumnConfigKey.RiEnd]: 'RIE',
    [ColumnConfigKey.RiEndYrs]: 'RIE, yrs',
    [ColumnConfigKey.OutOfRi]: 'Out of RI',
    [ColumnConfigKey.Maturity]: 'Maturity',
    [ColumnConfigKey.Vintage]: 'Vintage',
    [ColumnConfigKey.Trustee]: 'Trustee',
    [ColumnConfigKey.AmrDeal]: 'Deal with AMR',
    [ColumnConfigKey.StaticDeal]: 'Static Deal',
    [ColumnConfigKey.EuCompliance]: 'EU Comp.',
    [ColumnConfigKey.Esg]: 'ESG',
    [ColumnConfigKey.CollateralType]: 'Collat.',
    [ColumnConfigKey.Actions]: 'Actions',
    [ColumnConfigKey.Dealer]: 'Dealer',
    [ColumnConfigKey.Offer]: 'Offer, %',
    [ColumnConfigKey.CreditEnhancement]: 'C/E, %',
    [ColumnConfigKey.OfferDmBps]: 'DM, bps',
    [ColumnConfigKey.OfferYield]: 'Yld, %',
    [ColumnConfigKey.WalYears]: 'WAL',
    [ColumnConfigKey.Mvoc]: 'MVOC, %',
    [ColumnConfigKey.Description]: 'Note',
    [ColumnConfigKey.Coupon]: 'Coupon',
    [ColumnConfigKey.CurrentCoupon]: 'Cur. Coupon',
    [ColumnConfigKey.Bwic]: 'BWIC',
    [ColumnConfigKey.LastUpdateDate]: 'Last Updated',
    [ColumnConfigKey.CurrentBwic]: 'Current BWIC',
    [ColumnConfigKey.LastTraded]: 'Last Traded',
    [ColumnConfigKey.EvalPriceTalk]: 'K-Talk',
    [ColumnConfigKey.Bid]: 'Bid, %',
    [ColumnConfigKey.BidSize]: 'Bid Size, MM',
    [ColumnConfigKey.BidDmBps]: 'Bid DM, bps',
    [ColumnConfigKey.DealDocuments]: 'Docs',
    [ColumnConfigKey.ImTransaction]: 'Issuance Monitor',
    [ColumnConfigKey.ArrangerPipeline]: 'Arranger Pipeline',
    [ColumnConfigKey.RollerDeadline]: 'Roller Deadline',
    [ColumnConfigKey.CleansingNotice]: 'Cleansing Notice'
}
